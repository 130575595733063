import { useState } from 'react';
import {
  Card,
  Modal,
  ModalDialog,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Button,
  Box,
  ModalClose,
  LinearProgress,
  Stack,
  useTheme,
  CardOverflow
} from '@mui/joy';
import { DateTime } from 'luxon';
import Markdown from 'react-markdown';
import {
  Edit,
  Delete,
  WarningRounded,
  CardGiftcard
} from '@mui/icons-material';
import { getFirestore, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useMediaQuery } from '@mui/material';

import BirthdayDialog from './BirthdayDialog';
import { getCompletion } from '../modules/openai';
import { getBirthstones } from '../modules/birthstones';
import {
  zodiacSign,
  getElement,
  getChineseZodiac,
  getChineseElement
} from '../modules/astrology';

const capitaliseFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

const BirthdayPerson = props => {
  const { name, dob, notes, id } = props.birthday;
  const { userId } = props;
  const [editBirthday, setEditBirthday] = useState(false);
  const [showGiftIdeas, setShowGiftIdeas] = useState(false);
  const [deletionCheck, setDeletionCheck] = useState(false);
  const [giftIdeas, setGiftIdeas] = useState();

  const largeScreen = useMediaQuery(useTheme().breakpoints.up('md'));

  const birthday = dob.year
    ? DateTime.fromObject({
        day: dob.day,
        month: dob.month,
        year: dob.year
      }).toFormat('dd LLLL yyyy')
    : DateTime.fromObject({
        day: dob.day,
        month: dob.month
      }).toFormat('dd LLLL');

  const getGiftIdeas = async () => {
    setShowGiftIdeas(true);
    const giftIdeasRes = await getCompletion(
      `Suggest birthday gifts for ${name} that includes their birthstones. Their birthday is ${birthday}.${
        dob.year
          ? ` (age: ${
              DateTime.local()
                .diff(
                  DateTime.fromObject({
                    day: dob.day,
                    month: dob.month,
                    year: dob.year
                  }),
                  ['years', 'month']
                )
                .toObject().years
            }).`
          : ''
      } My notes on them: ${notes}`
    );
    setGiftIdeas(giftIdeasRes);
  };

  return (
    <Card variant="outlined">
      <CardOverflow variant="soft" color="primary" sx={{ py: 3 }}>
        <Typography level="h2">{name}</Typography>
        <Typography level="h6">
          {birthday}
          {dob.year
            ? ` (age: ${
                DateTime.local()
                  .diff(
                    DateTime.fromObject({
                      day: dob.day,
                      month: dob.month,
                      year: dob.year
                    }),
                    ['years', 'month']
                  )
                  .toObject().years
              })`
            : ''}
        </Typography>
        <Typography level="body-sm">
          Birthstones:{' '}
          {getBirthstones(
            DateTime.fromObject({
              day: dob.day,
              month: dob.month
            }).toFormat('LLLL')
          ).join(', ')}
          <br />
          {capitaliseFirstLetter(zodiacSign(props.birthday))} (
          {getElement(zodiacSign(props.birthday))} sign)
          {dob.year && (
            <>
              <br />
              Year of the{' '}
              {capitaliseFirstLetter(getChineseZodiac(props.birthday))} (
              {getChineseElement(props.birthday)} element)
            </>
          )}
        </Typography>
      </CardOverflow>
      <CardContent>
        <Typography
          level="body-md"
          sx={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}
        >
          {notes}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <IconButton
          onClick={() => getGiftIdeas()}
          variant="outlined"
          disabled={!notes}
        >
          <CardGiftcard />
        </IconButton>
        <IconButton onClick={() => setEditBirthday(true)} variant="outlined">
          <Edit />
        </IconButton>
        <IconButton variant="outlined" onClick={() => setDeletionCheck(true)}>
          <Delete />
        </IconButton>
      </CardActions>
      {editBirthday && (
        <BirthdayDialog
          dialog={{
            title: 'Update a birthday',
            message: 'Update their details, then hit save.',
            open: editBirthday
          }}
          handleSave={birthdayData => {
            const { name, day, month, year, notes } = birthdayData;
            updateDoc(doc(getFirestore(), 'users', userId, 'birthdays', id), {
              name,
              dob: {
                day: +day,
                month: +month,
                year: +year === 0 ? '' : +year
              },
              notes
            });
          }}
          defaultValues={{
            name,
            day: dob.day,
            month: dob.month,
            year: dob.year,
            notes
          }}
          handleClose={() => setEditBirthday(false)}
        />
      )}
      {deletionCheck && (
        <Modal open={deletionCheck} onClose={() => setDeletionCheck(false)}>
          <ModalDialog role="alertdialog">
            <Typography component="h2" startDecorator={<WarningRounded />}>
              Confirmation
            </Typography>
            <Typography
              id="alert-dialog-modal-description"
              textColor="text.tertiary"
            >
              Are you sure you want to remove {name}?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-end',
                pt: 2
              }}
            >
              <Button
                variant="plain"
                color="neutral"
                onClick={() => setDeletionCheck(false)}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                color="danger"
                onClick={() => {
                  setDeletionCheck(false);
                  deleteDoc(
                    doc(getFirestore(), 'users', userId, 'birthdays', id)
                  );
                }}
              >
                Yes, delete
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      )}
      {showGiftIdeas && (
        <Modal open={showGiftIdeas} onClose={() => setShowGiftIdeas(false)}>
          <ModalDialog size="lg" layout={largeScreen ? 'center' : 'fullscreen'}>
            <ModalClose variant="outlined" size="sm" />
            <Typography level="title-lg" startDecorator={<CardGiftcard />}>
              Gift ideas
            </Typography>
            <Stack spacing={2} style={{ overflow: 'scroll' }}>
              {!giftIdeas && <LinearProgress />}
              {giftIdeas && (
                <Typography level="body-md">
                  <Markdown>{giftIdeas}</Markdown>
                </Typography>
              )}
            </Stack>
          </ModalDialog>
        </Modal>
      )}
    </Card>
  );
};

export default BirthdayPerson;
