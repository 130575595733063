import { endOfDay, isAfter, isBefore, parse } from 'date-fns';

const getDate = (day, month) => parse(`${day}-${month}`, 'd-M', new Date());

// get the birthday as a Date object
const getBirthday = birthday => getDate(birthday.dob.day, birthday.dob.month);

/*
♈ Aries (Ram): March 21–April 19
♉ Taurus (Bull): April 20–May 20
♊ Gemini (Twins): May 21–June 21
♋ Cancer (Crab): June 22–July 22
♌ Leo (Lion): July 23–August 22
♍ Virgo (Virgin): August 23–September 22
♎ Libra (Balance): September 23–October 23
♏ Scorpius (Scorpion): October 24–November 21
♐ Sagittarius (Archer): November 22–December 21
♑ Capricornus (Goat): December 22–January 19
♒ Aquarius (Water Bearer): January 20–February 18
♓ Pisces (Fish): February 19–March 20
*/

export const zodiacSign = birthdayData => {
  const birthday = getBirthday(birthdayData);

  // is the birthday after date1 and before date2 (inclusive of those dates)
  const isBetween = (date1, date2) =>
    isAfter(endOfDay(birthday), date1) && isBefore(birthday, endOfDay(date2));

  if (isBetween(getDate(21, 3), getDate(19, 4))) {
    return 'aries';
  } else if (isBetween(getDate(20, 4), getDate(20, 5))) {
    return 'taurus';
  } else if (isBetween(getDate(21, 5), getDate(21, 6))) {
    return 'gemini';
  } else if (isBetween(getDate(22, 6), getDate(22, 7))) {
    return 'cancer';
  } else if (isBetween(getDate(23, 7), getDate(22, 8))) {
    return 'leo';
  } else if (isBetween(getDate(23, 8), getDate(22, 9))) {
    return 'virgo';
  } else if (isBetween(getDate(23, 9), getDate(23, 10))) {
    return 'libra';
  } else if (isBetween(getDate(24, 10), getDate(21, 11))) {
    return 'scorpio';
  } else if (isBetween(getDate(22, 11), getDate(21, 12))) {
    return 'sagittarius';
  } else if (
    isBetween(getDate(22, 12), getDate(31, 12)) ||
    isBetween(getDate(1, 1), getDate(19, 1))
  ) {
    return 'capricorn';
  } else if (isBetween(getDate(20, 1), getDate(18, 2))) {
    return 'aquarius';
  } else if (isBetween(getDate(19, 2), getDate(20, 3))) {
    return 'pisces';
  } else {
    return 'unknown';
  }
};

export const getElement = zodiacSign => {
  switch (zodiacSign) {
    case 'aries':
      return 'fire';
    case 'taurus':
      return 'earth';
    case 'gemini':
      return 'air';
    case 'cancer':
      return 'water';
    case 'leo':
      return 'fire';
    case 'virgo':
      return 'earth';
    case 'libra':
      return 'air';
    case 'scorpio':
      return 'water';
    case 'sagittarius':
      return 'fire';
    case 'capricorn':
      return 'earth';
    case 'aquarius':
      return 'air';
    case 'pisces':
      return 'water';
    default:
      return 'unknown';
  }
};

// Chinese zodiac is based on years in a 12-year cycle
export const getChineseZodiac = birthdayData => {
  if (!birthdayData.dob.year) {
    return null;
  }

  // The animals in order
  const animals = [
    'rat',
    'ox',
    'tiger',
    'rabbit',
    'dragon',
    'snake',
    'horse',
    'goat',
    'monkey',
    'rooster',
    'dog',
    'pig'
  ];

  // 1900 was the year of the rat
  const yearIndex = (birthdayData.dob.year - 1900) % 12;
  const adjustedIndex = yearIndex < 0 ? yearIndex + 12 : yearIndex;

  return animals[adjustedIndex];
};

// Get Chinese zodiac element with Yin/Yang distinction
export const getChineseElement = birthdayData => {
  if (!birthdayData.dob.year) {
    return null;
  }

  // Elements alternate between Yin and Yang
  // Complete 10-year cycle: Yang Metal, Yin Metal, Yang Water, Yin Water,
  // Yang Wood, Yin Wood, Yang Fire, Yin Fire, Yang Earth, Yin Earth
  const elements = [
    'Yang Metal',
    'Yin Metal',
    'Yang Water',
    'Yin Water',
    'Yang Wood',
    'Yin Wood',
    'Yang Fire',
    'Yin Fire',
    'Yang Earth',
    'Yin Earth'
  ];

  // Using 1900 as reference year
  const yearInCycle = (birthdayData.dob.year - 1900) % 10;
  const adjustedYear = yearInCycle < 0 ? yearInCycle + 10 : yearInCycle;

  return elements[adjustedYear];
};
